<template>
  <div>
        <header>
            <h1  class="header--title">Istituti comprensivi <span v-if="getIstituti && getIstituti.length > 0" class="badge badge--dark">{{ getIstituti.length }}</span></h1>
            <div class="header--right">

            <div class="searchContainer">
              <input type="text" placeholder="Cerca" @keyup="cercaIstituti()" v-model="key">
              <button @click="resetCercaIstituti()" v-if="key">x</button>
            </div>
            <NuovoIstituto />
            </div>
        </header>

        <table class="table" v-if="getIstituti">

            <tr class="table--header">
                  <th class="cell">
                    Nome
                  </th>
                  <th class="cell">
                    Azioni
                  </th>
            </tr>

            <tr v-for="istituto in getIstituti" :key="istituto.id" class="row">
              
                <td class="cell">
                <strong>{{ istituto.nome }}</strong>
                <span class="badge badge--success" v-if="getLastCreated && getLastCreated.content == istituto.id">Creato!</span>
                </td>
      
                <td class="cell">
                <ModificaIstituto :istituto="istituto"/>
                </td>
              
            </tr>
        </table>
        <template v-else>
            <UiPreloader/>
        </template>

  </div>
</template>

<script>
import NuovoIstituto from '@/components/editor/NuovoIstituto.vue'
import ModificaIstituto from '@/components/editor/ModificaIstituto.vue'

import { mapGetters, mapState, mapActions } from 'vuex'

export default {
  name: 'EditorIstituti',

  data: () => {
    return {
     istituti: [],
     key: '',
     showModal: false
    }
  },

  components: {
    NuovoIstituto,
    ModificaIstituto
  },
  
  computed: {
    ...mapGetters([
      'isLogged',
      'isAdmin',
      'isEducatore',
      'isInsegnante',
      'isClasse',
      'user'
    ]),

    ...mapGetters('istituti',[
        'getIstituti',
        'getLastCreated',
        'getIstitutiSearch'
     ]),

  },

  methods: {
    ...mapActions('istituti',[
        'fetchIstituti'
    ]),

    cercaIstituti() {
      this.tipo = '',
      this.key = event.target.value,
      this.getIstitutiSearch(event.target.value)
    },

    resetCercaIstituti() {
      this.tipo = '',
      this.key = '',
      this.getIstitutiSearch('')
    },


  },

  beforeMount() {
    this.$store.dispatch('istituti/fetchIstituti')
  }

}
</script>
