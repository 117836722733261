<template>
    <span>
    <button class="button button--small" @click="modalShow = true">Nuovo Istituto</button>
    <UiModal modalClass="modal--small" buttonClass="button--small" buttonLabel="Nuovo istituto" :modal="modalShow" :modalShow.sync="modalShow">
                <template v-slot:header>
                    <h4 class="modal--title">Nuovo istituto</h4>
                </template>

                <template v-slot:form>

                    <template v-if="!loading">
                        <ErrorsAlert />

                        <div class="form">
                            <div class="field-group flex">
                                <label>Nome istituto</label>
                                <input type="text" v-model="nome" ref="nome">
                            </div>

                            <div class="form--action">
                                <button class="button button--annulla" @click="modalShow = false">Annulla</button>
                                <button class="button button--conferma" @click.prevent="createIstituto">Salva</button> 
                            </div>
                        </div>
                        
                             
                    </template>
                    <template v-else>
                        <UiPreloader/>
                    </template>              

                </template>

     </UiModal>
    </span>
</template>
<script>

import ErrorsAlert from '@/components/ErrorsAlert.vue'
import { mapActions } from 'vuex'

export default {
    name: 'NuovoIstituto',

    data() {
        return {
            nome: '',
            loading: false,
            modalShow: false,
            prova: 'prova'
        }
    },

    props: {

    },

    watch: {
    // autofocus sull'input
    modalShow(val){
        if (val == true){
            this.$nextTick(() => {
                this.$refs.nome.focus()
            })
        }
      }
    },

    components: {
        ErrorsAlert
    },

    methods: {
    ...mapActions('istituti',[
        'addIstituto'
    ]),


    createIstituto () {
      this.loading = true
      this.addIstituto({
          nome: this.nome,
      }).then((response) => {
            this.nome = ''
            this.loading = false
            this.modalShow = false
            this.$store.dispatch('istituti/fetchIstituti')
      }).catch(e => {
            this.$store.state.success = null;
            this.$store.state.errors = e.response.data;
            this.loading = false
        })
    }

    }
}
</script>