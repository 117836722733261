<template>
    <span>
    <button class="button button--icon" @click="offcanvasShow = true"><svg class="icon" width="22" height="22"><use xlink:href="#edit" /></svg></button>
    <UiOffCanvas :offcanvas="offcanvasShow" :offcanvasShow.sync="offcanvasShow">

        <SuccessAlert/>
        <template v-slot:header>
            <h4>Modifica {{ istituto.nome }} </h4>
        </template>

        <template v-slot:form>
            <template v-if="!loading">
                <ErrorsAlert />
                <div class="form">
                    <div class="field-group flex">
                        <input type="text" v-model="nome">
                    </div>
                    
                    <div class="form--action">
                        <button class="button--delete" @click.prevent="modalShow = true" :disabled="disableSaveButton">Elimina istituto</button>
                        <button class="button button--small m-l-auto" @click.prevent="aggiornaIstituto(istituto)" :disabled="disableSaveButton">Salva</button>
                    </div>
                </div>
            </template>
            <template v-else>
                <UiPreloader/>
            </template>

        </template>

        <template v-slot:footer>
           
        </template>
     </UiOffCanvas>

     <UiModal :modal="modalShow" :modalShow.sync="modalShow">
            <template v-slot:header>
                <h4 class="modal--title">Sicuri di voler eliminare {{ istituto.nome }}?</h4>
            </template>

            <template v-slot:form>
                    <template v-if="!loading">
                    <div class="form">
                    <ErrorsAlert />
                    <p>Digita <strong>ELIMINA</strong> nel campo sottostante per eliminare <strong>{{ istituto.nome }}</strong>. <br/>Verranno eliminate tutte le scuole e classi di questo istituto</p>
                    <div class="field-group flex">
                        <div class="field col col-12">
                            <input type="text" v-model="confermaEliminazione">
                        </div>
                    </div>
                    <div class="form--action">
                        <button class="button button--annulla" @click="modalShow = false">Annulla</button>     
                        <button class="button button--conferma" @click.prevent="eliminaIstituto(istituto)" :disabled="confermaEliminazione != 'ELIMINA'">Conferma ed elimina</button>
                    </div>
                    </div>
                    </template>
                    <template v-else>
                        <UiPreloader/>
                    </template>
            </template>
     </UiModal>

    </span>
</template>
<script>

import SuccessAlert from '@/components/SuccessAlert.vue'
import ErrorsAlert from '@/components/ErrorsAlert.vue'
import { mapGetters, mapState, mapActions } from 'vuex'

export default {
    name: 'ModificaIstituto',

    data() {
        return {
            id: '',
            loading: false,
            disableSaveButton: false,
            offcanvasShow: false,
            nome: this.istituto.nome,
            modalShow: false,
            confermaEliminazione: ''
        }
    },
    props: {
        istituto: Object
    },

    components: {
        ErrorsAlert,
        SuccessAlert
    },

    methods: {
        ...mapActions('istituti',[
            'updateIstituto',
            'deleteIstituto'
        ]),

        aggiornaIstituto () {
            this.loading = true
            this.disableSaveButton = true
            this.$store.state.success = null
            this.updateIstituto({
                id:  this.istituto.id,
                nome: this.nome
            }).then((response) => {
                this.loading = false
                this.offcanvasShow = false
                this.disableSaveButton = false
                this.$store.dispatch('istituti/fetchIstituti')
            }).catch(e => {
                this.loading = false
                this.$store.state.success = null
                this.$store.state.errors = e.response.data;
                this.disableSaveButton = false
            })
        },

        eliminaIstituto (istituto) {
            this.loading = true
            this.disableSaveButton = true
            this.deleteIstituto(istituto).then((response) => {
                this.offcanvasShow = false
                this.loading = false
                this.disableSaveButton = false
                this.$store.dispatch('istituti/fetchIstituti')
            }).catch(error => {
                console.log('Errore:'+error)
            })
        },

    }
}
</script>